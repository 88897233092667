import {
  CLEAN_RECORDS_PAGINATION_DATA,
  FETCH_RECORDS,
  LOADING,
  SET_RECORDS_PAGINATION_DATA,
  STORE_RECORDS_FILTERS_DATA,
} from "actions/types";
import advisorhq from "apis/advisorhq";
import * as types from "../types";
import { activeAgencySelector, currentPageCaseSelector, pageSizeSelector, sortBySelector } from "../../reducers";
import { sortByParam } from "../../utils";
import { ENDPOINTS_TO_URL_MAPPING } from "../pagination/constants";
import { FETCH_RECORDS_BY_TYPE_MAP } from "../../pages/records/constants";

export const cleanRecords = () => async dispatch => {
  dispatch({ type: FETCH_RECORDS, payload: [] });
  dispatch({ type: CLEAN_RECORDS_PAGINATION_DATA });
};

export const fetchRecords = (filters, fetch_action) => async (dispatch, getState) => {
  const activeAgency = activeAgencySelector(getState());
  const pageSize = pageSizeSelector(getState());
  dispatch({ type: LOADING, payload: { records: true } });
  const page = currentPageCaseSelector(getState()) || 1;
  const sort_by = sortBySelector(getState());
  const sort_by_value = sortByParam(sort_by);
  const params = {
    agency: activeAgency.id,
    page: page,
    page_size: pageSize,
    sort_by: sort_by_value,
    ...filters,
  };

  const response = await advisorhq.get(ENDPOINTS_TO_URL_MAPPING.TASKS, {
    params,
  });

  let data = [];
  if (fetch_action === FETCH_RECORDS_BY_TYPE_MAP[0]) {
    let { results, ...paginateData } = response.data;
    data = results;
    paginateData = { ...paginateData, page };
    dispatch({ type: SET_RECORDS_PAGINATION_DATA, payload: paginateData });
  } else {
    data = response.data;
  }
  dispatch({ type: fetch_action, payload: data });
  dispatch({ type: LOADING, payload: { records: false } });
};

export const selectActiveRecordId = id => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_RECORD_ID, payload: id });
};

export const storeRecordsFiltersData = (filters, store_filters_action) => async (dispatch, getState) => {
  dispatch({ type: store_filters_action, payload: filters });
};
