import React from "react";
import { connect, useDispatch } from "react-redux";

import { activeProductSelector } from "reducers";
import { FaArrowLeft } from "react-icons/fa";
import { PERMISSIONS, URLS } from "../../constants";
import { Card } from "react-bootstrap";
import { setShouldSave, showModal } from "actions";
import { isAuthorized } from "../../utils";
import { useNavigate, useParams } from "react-router-dom";

const ProductTopHeader = ({ productInfo, ...props }) => {
  const { id } = useParams();
  const path = `/accounts/${id}`;
  const history = useNavigate();

  const SaveButton = saveFn => {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: 0 }}>
        <button
          className="btn btn--secondary navbar-save-button"
          onClick={async () => {
            await props.setShouldSave();
          }}
          style={{
            width: "100%",
          }}
        >
          Save Changes
        </button>
      </div>
    );
  };

  const BackToProductsButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: 0 }}>
        <button
          className="btn btn--secondary navbar-save-button"
          onClick={() => history(URLS.PRODUCTS)}
          style={{
            width: "100%",
          }}
        >
          <FaArrowLeft /> Back to Products
        </button>
      </div>
    );
  };

  let productName = "";
  let shortProductName = "";
  if (productInfo) {
    let insuredLivesVerbose = "";
    if (productInfo.number_of_insured_lives === 1) {
      insuredLivesVerbose = "Single Life";
    } else if (productInfo.number_of_insured_lives === 2) {
      insuredLivesVerbose = "Joint Life";
    }
    productName = `${productInfo.name} | ${productInfo.product_type} | ${insuredLivesVerbose} | ${productInfo.carrier_name} | Form #: ${productInfo.form_number} | ${productInfo.product_id}`;
    shortProductName = `${productInfo.product_id}`;
  }

  React.useEffect(() => {
    if (shortProductName) {
      document.title = `LifeRoc | ${shortProductName} | Product`;
    }
  }, [shortProductName]);

  return (
    <div
      style={{
        position: "fixed",
        top: "4rem",
        left: "0rem",
        width: "100%",
        zIndex: 25,
      }}
    >
      <Card style={{ border: "1px solid black", borderRadius: "0px" }}>
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            backgroundColor: "black",
          }}
        >
          <h5
            className="section-title navbar-title"
            style={{
              color: "white",
              alignItems: "center",
              marginBottom: 0,
              lineHeight: 2,
            }}
          >
            Product: {productName}
          </h5>
          <div style={{ display: "flex" }}>
            <BackToProductsButton />
            <SaveButton saveFn={props.saveFn} />
          </div>
        </Card.Header>
      </Card>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const roles = state.auth.activeAgencyRoles;
  const productInfo = activeProductSelector(state);

  return {
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
    productInfo,
  };
};

export default connect(mapStateToProps, {
  showModal,
  setShouldSave,
})(ProductTopHeader);
