import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import Collapsible from "components/Collapsible";
import { DateInput, GenderSelect, Select, StateSelect, TextInput } from "components/forms";
import { rateClassChoices, tableRateChoices } from "constants.js";
import { makeChoices } from "../../funders/utils";
import { fetchProducts } from "actions";
import { winflexChoicesSelector } from "../../../reducers";
import { useFormikContext } from "formik";

const WinFlexForm = props => {
  const { setFieldValue } = useFormikContext();
  const initialChoices = makeChoices(props.winflexChoices?.["Lincoln WealthPreserve 2 IUL"]?.rate_classes);
  const [rateClassChoicesForSelectedScript, setRateClassChoicesForSelectedScript] = useState(initialChoices);
  const [tableRateChoicesForSelectedScript, setTableRateChoicesForSelectedScript] = useState([]);
  const [nlgPeriod, setNLGPeriod] = useState(25);
  const [endYear, setEndYear] = useState(100);

  const handleSelectedScriptChange = e => {
    if (!e) {
      setFieldValue("selected_script", undefined);
      setFieldValue("selected_rate_class", undefined);
      setFieldValue("selected_table_rating", undefined);
      return;
    }
    console.log("selected", e.value, props.winflexChoices[e.value]);
    setFieldValue("selected_script", e.value);
    setFieldValue("selected_rate_class", undefined);
    setFieldValue("selected_table_rating", undefined);

    let _rateClassChoices = props.winflexChoices[e.value].rate_classes;
    setRateClassChoicesForSelectedScript(makeChoices(_rateClassChoices));

    let _nlg_length = props.winflexChoices[e.value].nlg_length;
    setNLGPeriod(_nlg_length);
    setFieldValue("nlg_length", _nlg_length);

    let _end_year = props.winflexChoices[e.value].end_year || 100;
    setEndYear(_end_year);
    setFieldValue("end_year", _end_year);
  };

  const handleNLGPeriodChange = e => {
    const value = e.target.value;
    setFieldValue("nlg_length", value);
    setNLGPeriod(value);
  };

  const handleEndYearChange = e => {
    const value = e.target.value;
    setFieldValue("end_year", value);
    setEndYear(value);
  };

  return (
    <>
      <hr />
      <Collapsible title="WinFlex Illustration Detail">
        <Form.Row>
          <TextInput label="Insured Name" name="insured_name" disabled />
          <GenderSelect label="Insured Gender" name="insured_gender" disabled />
          <DateInput label="Insured DOB" name="insured_dob" disabled />
          <TextInput label="Insured Age" name="insured_age" disabled />
        </Form.Row>
        <Form.Row>
          <StateSelect label="Jurisdiction" name="jurisdiction" disabled md={2} />
          <TextInput label="Carrier" name="carrier" disabled />
          <TextInput label="Product" name="product" disabled />
          <Select label="Rate Class" name="rate_class" options={rateClassChoices} disabled />
          <Select label="Table Rating" name="table_rating" options={tableRateChoices} disabled />
        </Form.Row>
        <hr />
        <Form.Row>
          <StateSelect name="selected_state" md={2} />
          <Select
            label="Script"
            name="selected_script"
            placeholder="Select Script"
            options={props.scriptChoices}
            onChange={handleSelectedScriptChange}
            md={5}
          />
          <Select
            label="Select Rate Class"
            name="selected_rate_class"
            placeholder="Select Rate Class"
            options={rateClassChoicesForSelectedScript}
          />
          <Select
            label="Select Table Rating"
            name="selected_table_rating"
            placeholder="Select Table Rating"
            options={tableRateChoicesForSelectedScript}
            disabled
          />
        </Form.Row>
        <Form.Row>
          <TextInput type="number" label="Input Age" name="input_age" step="1" />
          <TextInput
            type="number"
            label="End
            Year"
            name="end_year"
            step="1"
            value={endYear}
            onChange={handleEndYearChange}
          />
          <TextInput
            type="number"
            label="NLG Period"
            name="nlg_length"
            step="1"
            value={nlgPeriod}
            onChange={handleNLGPeriodChange}
          />
          <Select
            label="WinFlex Log-In"
            name="winflex_login"
            disabled
            placeholder="Select Log-In"
            options={props.winFlexLoginChoices}
            defaultValue={props.winFlexLoginChoices[0]}
          />
        </Form.Row>
      </Collapsible>
      <hr />
    </>
  );
};

const mapStateToProps = state => {
  const winflexChoices = winflexChoicesSelector(state);
  return {
    winflexChoices,
    scriptChoices: makeChoices(Object.keys(winflexChoices).sort()),
    winFlexLoginChoices: makeChoices(["agiroux@ims4u.net"]),
  };
};

export default connect(mapStateToProps, {
  fetchProducts,
  winflexChoicesSelector,
})(WinFlexForm);
