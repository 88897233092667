import React from "react";
import { connect } from "react-redux";

import { showModal, selectAccountTask } from "actions";
import { activeAccountSelector } from "reducers";
import { ACCOUNT_TASK_MODAL, DELETE_ACCOUNT_TASK_MODAL } from "components/modals";
import Table, { dateCell, datetimeCell, notesCell } from "components/table";
import IconLink from "../../../../components/IconLink";
import { FaEdit, FaTrash } from "react-icons/fa";
import { TASK_STATUS_MAP, TASK_TYPES_MAP } from "../../constants";

const AccountTaskTable = ({ activeAccount, ...props }) => {
  if (!activeAccount || !props.tasks) {
    // Still didn't fetch data
    return null;
  }

  const columns = [
    {
      Header: "Action",
      className: "action",
      Cell: ({ row }) => {
        const taskId = row.original.id;
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                props.selectAccountTask(row.original);
                props.showModal(ACCOUNT_TASK_MODAL);
              }}
            />
            <IconLink
              Icon={FaTrash}
              iconConfig={{ className: "table--action-icon btn--red" }}
              tooltip="Delete"
              onClick={() => {
                props.showModal(DELETE_ACCOUNT_TASK_MODAL, {
                  id: taskId,
                });
              }}
            />
          </>
        );
      },
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ row }) => {
        const type_value = row.original.type;
        if (type_value !== null) {
          return TASK_TYPES_MAP[type_value] || "";
        } else {
          return "";
        }
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const status_value = row.original.status;
        if (status_value !== null) {
          return TASK_STATUS_MAP[status_value] || "";
        } else {
          return "";
        }
      },
    },
    {
      Header: "Task Owner",
      accessor: "owner_name",
    },
    {
      Header: "Task Creator",
      accessor: "created_by",
    },
    {
      Header: "Create Date",
      accessor: "date_created",
      Cell: dateCell,
    },
    {
      Header: "Last Edited By",
      accessor: "last_edited_by_name",
    },
    {
      Header: "Last Edit Date",
      accessor: "last_edit_date",
      Cell: datetimeCell,
    },
    {
      Header: "Follow Up Date",
      accessor: "due_date",
      Cell: dateCell,
    },
    {
      Header: "Notes",
      accessor: "notes",
      Cell: notesCell,
    },
  ];

  return (
    <Table columns={columns} data={props.tasks} defaultSort={true} paginated={false} emptyMessage="No Tasks found" />
  );
};

const mapStateToProps = state => {
  const activeAccount = activeAccountSelector(state);

  return {
    activeAccount,
    tasks: activeAccount ? activeAccount.tasks : [],
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectAccountTask,
})(AccountTaskTable);
