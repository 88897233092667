import {
  SORT_BY,
  FUNDERS_SORT_BY,
  SET_ACTIVE_TAB,
  CLEAN_LIFE_SETTLEMENT_SEARCH_DATA,
  STORE_CASES_FILTERS_DATA,
  STORE_LE_FILTERS_DATA,
  STORE_TRADING_FILTERS_DATA,
  STORE_TRADING_STAGE_FILTER,
  STORE_RECORDS_FILTERS_DATA,
  STORE_CASE_RECORDS_FILTERS_DATA,
  STORE_CASE_REQUIREMENT_RECORDS_FILTERS_DATA,
  STORE_ACCOUNT_RECORDS_FILTERS_DATA,
  STORE_LEAD_RECORDS_FILTERS_DATA,
  STORE_ACCOUNTS_FILTERS_DATA,
  STORE_LEADS_FILTERS_DATA,
  STORE_PARAMETERS_FILTERS_DATA,
} from "actions/types";

export const defaultState = {
  filters: {},
  casesFilters: {},
  recordsFilters: {},
  caseRecordsFilters: {},
  caseRequirementRecordsFilters: {},
  accountRecordsFilters: {},
  leadRecordsFilters: {},
  accountsFilters: {},
  leadsFilters: {},
  tradingFilters: {
    stage: "Opportunity,Price Discovery,In Closing",
  },
  parametersFilters: {},
  sortBy: {
    id: "submission_date",
    desc: true,
  },
  fundersSortBy: {
    id: "name",
    desc: false,
  },
  activeTab: "cases",
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case STORE_CASES_FILTERS_DATA:
      if (action.payload) {
        return {
          ...state,
          casesFilters: action.payload,
        };
      } else {
        return {
          ...state,
          casesFilters: {},
        };
      }
    case STORE_TRADING_FILTERS_DATA:
      if (action.payload) {
        return {
          ...state,
          tradingFilters: action.payload,
        };
      } else {
        return {
          ...state,
          tradingFilters: {},
        };
      }
    // RECORDS / CASE_RECORDS / CASE_REQUIREMENT_RECORDS / ACCOUNT_RECORDS / LEAD_RECORDS
    case STORE_RECORDS_FILTERS_DATA:
      console.log(action);
      if (action.payload) {
        return {
          ...state,
          recordsFilters: action.payload,
        };
      } else {
        return {
          ...state,
          recordsFilters: {},
        };
      }
    case STORE_CASE_RECORDS_FILTERS_DATA:
      if (action.payload) {
        return {
          ...state,
          caseRecordsFilters: action.payload,
        };
      } else {
        return {
          ...state,
          caseRecordsFilters: {},
        };
      }
    case STORE_CASE_REQUIREMENT_RECORDS_FILTERS_DATA:
      if (action.payload) {
        return {
          ...state,
          caseRequirementRecordsFilters: action.payload,
        };
      } else {
        return {
          ...state,
          caseRequirementRecordsFilters: {},
        };
      }
    case STORE_ACCOUNT_RECORDS_FILTERS_DATA:
      if (action.payload) {
        return {
          ...state,
          accountRecordsFilters: action.payload,
        };
      } else {
        return {
          ...state,
          accountRecordsFilters: {},
        };
      }
    case STORE_LEAD_RECORDS_FILTERS_DATA:
      if (action.payload) {
        return {
          ...state,
          leadRecordsFilters: action.payload,
        };
      } else {
        return {
          ...state,
          leadRecordsFilters: {},
        };
      }
    case STORE_ACCOUNTS_FILTERS_DATA:
      if (action.payload) {
        return {
          ...state,
          accountsFilters: action.payload,
        };
      } else {
        return {
          ...state,
          accountsFilters: {},
        };
      }
    case STORE_TRADING_STAGE_FILTER:
      if (action.payload) {
        return {
          ...state,
          tradingFilters: {
            ...state.tradingFilters,
            stage: action.payload.stage,
          },
        };
      } else {
        return {
          ...state,
          tradingFilters: {
            ...state.tradingFilters,
            stage: "",
          },
        };
      }
    case STORE_LEADS_FILTERS_DATA:
      if (action.payload) {
        return {
          ...state,
          leadsFilters: action.payload,
        };
      } else {
        return {
          ...state,
          leadsFilters: {},
        };
      }
    case STORE_PARAMETERS_FILTERS_DATA:
      if (action.payload) {
        return {
          ...state,
          parametersFilters: action.payload,
        };
      } else {
        return {
          ...state,
          parametersFilters: {},
        };
      }
    case CLEAN_LIFE_SETTLEMENT_SEARCH_DATA:
      return defaultState;
    case STORE_LE_FILTERS_DATA:
      if (action.payload) {
        return {
          ...state,
          leFilters: action.payload,
        };
      } else {
        return {
          ...state,
          leFilters: {},
        };
      }
    case SET_ACTIVE_TAB:
      if (action.payload) {
        return {
          ...state,
          activeTab: action.payload,
        };
      } else {
        return {
          ...state,
        };
      }
    case SORT_BY:
      if (action.payload) {
        return {
          ...state,
          sortBy: {
            id: action.payload.id,
            desc: action.payload.desc,
          },
        };
      } else {
        return {
          ...state,
          sortBy: {},
        };
      }
    case FUNDERS_SORT_BY:
      if (action.payload) {
        return {
          ...state,
          fundersSortBy: {
            id: action.payload.id,
            desc: action.payload.desc,
          },
        };
      } else {
        return {
          ...state,
          fundersSortBy: {},
        };
      }
    default:
      return state;
  }
};

export const activeTabSelector = state => state.filters.activeTab;

export const storedLeFiltersSelector = state => state.leFilters;

export const storedCasesFiltersSelector = state => state.casesFilters;

export const storedTradingFiltersSelector = state => state.tradingFilters;

export const storedRecordsFiltersSelector = state => state.recordsFilters;

export const storedCaseRecordsFiltersSelector = state => state.caseRecordsFilters;

export const storedCaseRequirementRecordsFiltersSelector = state => state.caseRequirementRecordsFilters;

export const storedAccountRecordsFiltersSelector = state => state.accountRecordsFilters;

export const storedLeadRecordsFiltersSelector = state => state.leadRecordsFilters;

export const storedAccountsFiltersSelector = state => state.accountsFilters;

export const storedLeadsFiltersSelector = state => state.leadsFilters;

export const storedParametersFiltersSelector = state => state.parametersFilters;

export const sortBySelector = state => state.sortBy;

export const fundersSortBySelector = state => state.fundersSortBy;

export const initialCasesPageResultsSelector = state => {
  return (state.cases.page - 1) * state.pageSize;
};

export const initialLesPageResultsSelector = state => {
  return (state.les.page - 1) * state.pageSize;
};

export const initialRecordsPageResultsSelector = state => {
  return (state.records.page - 1) * state.pageSize;
};

export const initialAccountsPageResultsSelector = state => {
  return (state.accounts.page - 1) * state.pageSize;
};

export const initialLeadsPageResultsSelector = state => {
  return (state.leads.page - 1) * state.pageSize;
};

export const initialParametersPageResultsSelector = state => {
  return (state.parameters.page - 1) * state.pageSize;
};
