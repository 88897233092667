import React from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import logo from "logo.png";
import * as notifications from "notifications";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { logout, selectAgency, fetchFunders, cleanLifeSettlementSearchData } from "actions";
import { activeAgencySelector, agenciesSelector, loginInfoSelector } from "reducers";
import { PERMISSIONS, URLS } from "../constants";
import { isAuthorized, redirectTo } from "../utils";
import advisorhq from "../apis/advisorhq";
import { getServerHost, getWebSocketProtocol } from "../actions/life_settlement/utils";

const NavbarLink = ({ url, title, ...props }) => {
  const location = useLocation();
  const split = location.pathname.split("/");
  let isActive = false;
  if (split.length > 1) {
    const firstPart = split[1];
    if (url.startsWith("/riskx")) {
      isActive = firstPart.includes("riskx") || firstPart.includes("life-expectancies");
    } else {
      isActive = firstPart.indexOf(url.replaceAll("/", "")) !== -1;
    }
  }
  let activeClass = isActive ? "navbar-active" : "";
  return (
    <Link className={"nav-link " + activeClass} to={url} props={props}>
      {title}
    </Link>
  );
};

// example taken from https://react-bootstrap.netlify.com/components/navbar/#navbars
const AHQNavbar = ({ activeAgency, agencies, email, cleanLifeSettlementSearchData, roles, ...props }) => {
  const onAgencyChange = async agency => {
    const response = await advisorhq.patch("api/select_agency/", {
      active_agency: agency.id,
    });
    props.selectAgency({
      active_agency: response.data.active_agency,
      active_agency_roles: response.data.active_agency_roles,
    });
  };

  const displayAgencies = () => {
    let displayAgencyName = activeAgency?.name || "Select An Agency";
    let activeAgencyRole = roles.length > 0 ? roles[0] : "";

    if (agencies.length === 1) {
      return (
        <div style={{ marginTop: 5 }}>
          {displayAgencyName} ({activeAgencyRole})
        </div>
      );
    }
    return (
      <NavDropdown title={`${displayAgencyName} (${activeAgencyRole})`} id="agency-dropdown">
        {agencies.map(agency => (
          <NavDropdown.Item key={agency.id} onClick={() => onAgencyChange(agency)}>
            {agency.name} ({agency.role})
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    );
  };

  const [nTasks, setNTasks] = React.useState(0);
  React.useEffect(() => {
    if (props.userId) {
      let server_host = getServerHost();
      let protocol = getWebSocketProtocol();
      let url = `${protocol}://${server_host}socket-server/tasks/${props.userId}/`;
      const ws = new WebSocket(url);

      // Listen for the connection event
      ws.onopen = () => {
        // Send a request for tasks update
        const requestTaskUpdate = {
          type: "request_tasks_update",
          user_id: props.userId,
        };
        ws.send(JSON.stringify(requestTaskUpdate));
      };

      ws.onmessage = event => {
        const data = JSON.parse(event.data);
        console.log("websocket onmessage", data);
        if (data.past_due_tasks !== undefined) {
          setNTasks(data.past_due_tasks);
        }
      };
    }
  }, [props.userId]);

  const prevNTasksRef = React.useRef(nTasks);
  React.useEffect(() => {
    const prevNTasks = prevNTasksRef.current;

    if (prevNTasks && prevNTasks !== nTasks) {
      console.log("nTasks changed:", { previous: prevNTasks, current: nTasks });

      if (nTasks > prevNTasks) {
        notifications.info("New Due Tasks");
      }
    }

    prevNTasksRef.current = nTasks;
  }, [nTasks]); // Dependency on nTasks

  let myTasksTitle = "My Tasks";
  if (nTasks) {
    myTasksTitle = `My Tasks (${nTasks})`;
  }

  return (
    <Navbar
      style={{ position: "fixed", top: 0, width: "100%", zIndex: 99, height: "4rem" }}
      expand="lg"
      className="shadowed"
    >
      <Navbar.Brand href={URLS.HOME}>
        <img src={logo} alt="advisor hq" width="114" height="33" />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      {props.isSignedIn && (
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavbarLink title={myTasksTitle} url={URLS.TASKS} />
            <NavbarLink title={"Accounts"} url={URLS.ACCOUNTS} />
            <NavbarLink title={"Carriers"} url={URLS.CARRIERS} />
            <NavbarLink title={"Cases"} url={URLS.HOME} onClick={() => cleanLifeSettlementSearchData()} />
            {props.hasAdminPermission && <NavbarLink title={"Funders"} url={URLS.FUNDERS} />}
            <NavbarLink title={"Leads"} url={URLS.LEADS} />
            {props.hasAdminPermission && <NavbarLink title={"Products"} url={URLS.PRODUCTS} />}
            {props.hasAdminPermission && <NavbarLink title={"RiskX"} url={URLS.PARAMETERS} />}
            {props.hasAdminPermission && <NavbarLink title={"System Admin"} url={URLS.ADMIN_EMAILS} />}
            {props.hasAdminPermission && <NavbarLink title={"Trading"} url={URLS.TRADING} />}
          </Nav>

          {displayAgencies()}

          <NavDropdown title={email} id="account-dropdown">
            <NavDropdown.Item eventKey="4.1" onClick={props.logout}>
              Logout
            </NavDropdown.Item>
            <NavDropdown.Item eventKey="reset-link" onClick={() => redirectTo("/auth/reset-password")}>
              Reset Password
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      )}
    </Navbar>
  );
};

const mapStateToProps = state => {
  const { isSignedIn, email } = loginInfoSelector(state);
  const roles = state.auth.activeAgencyRoles;
  return {
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
    activeAgency: activeAgencySelector(state),
    agencies: agenciesSelector(state),
    isSignedIn,
    email,
    roles,
    userId: state.auth.user_id,
  };
};

export default connect(mapStateToProps, {
  logout,
  selectAgency,
  fetchFunders,
  cleanLifeSettlementSearchData,
})(AHQNavbar);
