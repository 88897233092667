import React from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import { useField, useFormikContext } from "formik";

import { isInvalid } from "./validation";
import Label from "./Label";
import FieldErrors from "./FieldErrors";
import FieldHelpText from "./FieldHelpText";

const TextInput = ({ label, md = "", helpText, isRequired = false, tooltip = false, lowercase = false, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  if (!props.autoComplete) {
    props.autoComplete = "off";
  }

  const handleChange = e => {
    const value = e.target.value;
    setFieldValue(field.name, value);
  };

  const handleLowercaseChange = e => {
    let value = e.target.value;
    value = value.toLowerCase();
    setFieldValue(field.name, value);
  };

  let onChange;
  if (props.onChange) {
    onChange = props.onChange;
  } else {
    onChange = lowercase ? handleLowercaseChange : handleChange;
  }

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`} style={props.customStyle}>
      {label ? <Label label={label} tooltip={tooltip} required={isRequired} /> : null}
      <InputGroup>
        {props.prepend && (
          <InputGroup.Prepend>
            <InputGroup.Text>{props.prepend}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <Form.Control {...field} {...props} onChange={onChange} isInvalid={isInvalid(meta)} style={props.grayout} />
        {props.append && (
          <InputGroup.Append>
            <InputGroup.Text>{props.append}</InputGroup.Text>
          </InputGroup.Append>
        )}
        <FieldErrors meta={meta} />
      </InputGroup>
      {helpText && <FieldHelpText>{helpText}</FieldHelpText>}
    </Form.Group>
  );
};

export default TextInput;
