import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { LEAD_TASK_MODAL } from "components/modals";
import LeadTaskForm from "./LeadTaskForm";

const LeadTaskModal = () => {
  return (
    <FullPageModal modalType={LEAD_TASK_MODAL} style={{ content: { overflow: "visible" } }}>
      <LeadTaskForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(LeadTaskModal);
