import React from "react";
import { connect } from "react-redux";

import { showModal, downloadFile, selectCaseTask } from "actions";
import { activeCaseSelector } from "reducers";
import Table, { dateCell, dateOverdueCell, datetimeCell } from "components/table";
import IconLink from "../../../components/IconLink";
import { FaEdit, FaTrash } from "react-icons/fa";
import { CASE_TASK_MODAL, DELETE_CASE_TASK_MODAL } from "components/modals";

const CaseTasksTable = ({ activeCase, ...props }) => {
  if (!activeCase) {
    // Still didn't fetch data
    return null;
  }

  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        console.log(row.original);
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                props.selectCaseTask(row.original);
                props.showModal(CASE_TASK_MODAL);
              }}
            />
            <IconLink
              Icon={FaTrash}
              iconConfig={{ className: "table--action-icon btn--red" }}
              tooltip="Delete"
              onClick={() => {
                props.showModal(DELETE_CASE_TASK_MODAL, {
                  id: row.original.task_obj?.id,
                });
              }}
            />
          </>
        );
      },
    },
    {
      Header: "Subject",
      accessor: "subject",
    },
    {
      Header: "Owner",
      accessor: "record_owner",
    },
    {
      Header: "Created By",
      accessor: "record_creator",
    },
    {
      Header: "Date Created",
      accessor: "date_created",
      Cell: datetimeCell,
    },
    {
      Header: "Last Edited By",
      accessor: "last_editor",
    },
    {
      Header: "Last Edit Date",
      accessor: "last_edit_date",
      Cell: datetimeCell,
    },
    {
      Header: "Due Date",
      accessor: "due_date",
      Cell: dateOverdueCell,
    },
  ];

  return (
    <Table columns={columns} data={props.tasks} defaultSort={true} paginated={false} emptyMessage="No Tasks found" />
  );
};

const mapStateToProps = state => {
  const activeCase = activeCaseSelector(state) || {};

  return {
    activeCase,
    tasks: activeCase?.tasks || [],
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectCaseTask,
  downloadFile,
})(CaseTasksTable);
