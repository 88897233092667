import React, { useRef } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import {
  Select,
  TextInput,
  FormFilter,
  SubmitSearchButton,
  ClearFiltersButton,
  DateInput,
  CurrencyInput,
  StateSelect,
  BooleanSelect,
  RowSeparator,
} from "components/forms";
import {
  fetchLifeSettlementCases,
  fetchLifeSettlementChoices,
  fetchManagerAgents,
  storeCasesFiltersData,
  cleanCasesPaginationData,
} from "actions";
import { agentsChoicesSelector, managementChoicesSelector, storedCasesFiltersSelector } from "reducers";
import CATEGORY_MAP, { FRID_CHOICES } from "../caseFiles/constants";
import { parenthesisWrap } from "../../../utils";
import { caseStatusChoices, productTypeChoices, stageChoices, statusChoicesDocumentStatus } from "../../../constants";

const CasesFilterForm = ({ fetchLifeSettlementChoices, fetchManagerAgents, currentFilters, ...props }) => {
  React.useEffect(() => {
    fetchLifeSettlementChoices();
    fetchManagerAgents();
  }, [fetchLifeSettlementChoices]);

  const handleStatusChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedStatusValues = selectedOptions.map(o => o.value).join(",");
      setStatus(commaSeparatedStatusValues);
    } else {
      setStatus(undefined);
    }
  };

  const handleStageChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedValues = selectedOptions.map(o => o.value).join(",");
      setStage(commaSeparatedValues);
    } else {
      setStage(undefined);
    }
  };

  const handleFridChange = selectedOption => {
    if (selectedOption) {
      setFrid(selectedOption);
    } else {
      setFrid(undefined);
      setFridStatus(undefined);
    }
  };

  const handleFridStatusChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedStatusValues = selectedOptions.map(o => o.value).join(",");
      setFridStatus(commaSeparatedStatusValues);
    } else {
      setFridStatus(undefined);
    }
  };

  let parsedCurrentFiltersFrid = [];
  if (currentFilters && currentFilters.frid && props.fridList) {
    const value = currentFilters.frid;
    let fridData = props.fridList.find(e => e.value === value);
    if (fridData) {
      parsedCurrentFiltersFrid = { value: fridData.value, label: fridData.label };
    }
  }

  let status_initial = [];
  let [status, setStatus] = React.useState();
  let [frid_state, setFrid] = React.useState(parsedCurrentFiltersFrid);
  let frid_status_initial = [];
  let [frid_status_state, setFridStatus] = React.useState(currentFilters?.frid_status);
  let stage_initial = [];
  let [stage, setStage] = React.useState();

  if (currentFilters && currentFilters.status) {
    let status_list = currentFilters && currentFilters.status.split(",");
    for (let status_code of status_list) {
      let stateData = caseStatusChoices.find(e => e.value === status_code);
      status_initial.push({ value: stateData.value, label: stateData.label });
    }
  }

  if (currentFilters && currentFilters.frid_status) {
    let status_list = currentFilters && currentFilters.frid_status.split(",");
    for (let status of status_list) {
      let stateData = statusChoicesDocumentStatus.find(e => e.value === Number(status));
      stateData && frid_status_initial.push({ value: stateData.value, label: stateData.label });
    }
  }

  let initialValues = {
    id: (currentFilters && currentFilters.id) || "",
    policy_number: (currentFilters && currentFilters.policy_number) || "",
    status,
    frid: frid_state,
    frid_status: frid_status_state,
    stage,
    provider: (currentFilters && currentFilters.provider) || "",
    source: (currentFilters && currentFilters.source) || "",
    broker: (currentFilters && currentFilters.broker) || "",
    carrier: (currentFilters && currentFilters.carrier) || "",
    product_type: (currentFilters && currentFilters.product_type) || "",
    case_manager: (currentFilters && currentFilters.case_manager) || "",
    pricing_manager: (currentFilters && currentFilters.pricing_manager) || "",
    auction_manager: (currentFilters && currentFilters.auction_manager) || "",
    closing_manager: (currentFilters && currentFilters.closing_manager) || "",
    sales_representative: (currentFilters && currentFilters.sales_representative) || "",
    sales_manager: (currentFilters && currentFilters.sales_manager) || "",
    national_account_manager: (currentFilters && currentFilters.national_account_manager) || "",
    national_account: (currentFilters && currentFilters.national_account) || "",
    jurisdiction: (currentFilters && currentFilters.jurisdiction) || "",
    first_name: (currentFilters && currentFilters.first_name) || "",
    last_name: (currentFilters && currentFilters.last_name) || "",
    face_amount_min: (currentFilters && currentFilters.face_amount_min) || "",
    face_amount_max: (currentFilters && currentFilters.face_amount_max) || "",
    submission_date_from: (currentFilters && currentFilters.submission_date_from) || "",
    submission_date_to: (currentFilters && currentFilters.submission_date_to) || "",
    escrow_release_date_from: (currentFilters && currentFilters.escrow_release_date_from) || "",
    escrow_release_date_to: (currentFilters && currentFilters.escrow_release_date_to) || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        props.storeCasesFiltersData({
          ...values,
          status: status,
          frid: frid_state && frid_state.value,
          frid_status: frid_status_state,
          stage: stage,
        });
        props.cleanCasesPaginationData();
        // TODO: REPLACE FOR FetchPage
        await props.fetchLifeSettlementCases({
          ...values,
          status: status,
          frid: frid_state && frid_state.value,
          frid_status: frid_status_state,
          stage: stage,
        });
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, setFieldValue, setSubmitting }) => {
        const stageRef = useRef();
        const statusRef = useRef();
        const fridRef = useRef();
        const fridStatusRef = useRef();

        const clearFridHandler = () => {
          fridStatusRef.current?.select?.props?.value && fridStatusRef.current.select.clearValue();
        };

        const clearFridStatusHandler = () => {
          fridStatusRef.current.select.clearValue();
        };

        const resetHandler = () => {
          for (const initialValuesKey in initialValues) {
            setFieldValue(initialValuesKey, "");
          }
          props.storeCasesFiltersData({});
          setFrid(undefined);
          setFridStatus(undefined);
          props.cleanCasesPaginationData();
          props.fetchLifeSettlementCases(); // TODO: Await needed?
          setSubmitting(false);
          stageRef.current.select.clearValue();
          statusRef.current.select.clearValue();
          fridRef.current.select.clearValue();
          fridStatusRef.current.select.clearValue();
        };

        return (
          <FormFilter title={"Case Search"} onSubmit={handleSubmit}>
            <Form.Row>
              <TextInput label="Last Name" name="last_name" />
              <TextInput label="First Name" name="first_name" />
              <TextInput label="Case ID" name="id" />
              <TextInput label="Policy Number" name="policy_number" />
            </Form.Row>
            <Form.Row>
              <Select
                label="Stage"
                name="stage"
                placeholder="Select Stage"
                isMulti={true}
                onChange={handleStageChange}
                options={stageChoices}
                defaultValue={stage_initial}
                submitRef={stageRef}
              />
              <Select
                label="Status"
                name="status"
                placeholder="Select Status"
                isMulti={true}
                onChange={handleStatusChange}
                options={caseStatusChoices}
                defaultValue={status_initial}
                submitRef={statusRef}
              />
              <Select
                label="Carrier"
                name="carrier"
                placeholder="Select Carrier"
                options={props.carrierChoices.getChoices()}
              />
              <Select
                label="Product Type"
                name="product_type"
                placeholder="Select Product"
                options={productTypeChoices}
              />
            </Form.Row>
            <Form.Row>
              <CurrencyInput label="Face Amount (Min)" name="face_amount_min" />
              <CurrencyInput label="Face Amount (Max)" name="face_amount_max" />
              <DateInput label="Create Start" name="submission_date_from" />
              <DateInput label="Create End" name="submission_date_to" />
            </Form.Row>
            <Form.Row>
              <StateSelect label="Jurisdiction" name="jurisdiction" placeholder="Select Jurisdiction" md={3} />
              <Select
                label="Provider"
                name="provider"
                placeholder="Select Provider"
                options={props.providerChoices.getChoices()}
                md={3}
              />
              <DateInput label="Seller Escrow Release - From" name="escrow_release_date_from" />
              <DateInput label="Seller Escrow Release - To" name="escrow_release_date_to" />
            </Form.Row>
            <Form.Row>
              <BooleanSelect label="Offer Made" name="offer_made" placeholder="Offer Made" md={3} />
              <Select
                label="Source"
                name="source"
                placeholder="Select Source"
                options={props.sourceAndBrokerChoices.getChoices()}
                md={3}
              />
              <Select
                label="Broker"
                name="broker"
                placeholder="Select Broker"
                options={props.sourceAndBrokerChoices.getChoices()}
                md={3}
              />
            </Form.Row>
            <Form.Row>
              <Select
                label="Sales Rep"
                name="sales_representative"
                placeholder="Select Sales Rep"
                options={props.salesRepChoices.getChoices({ sortByLabel: true })}
              />
              <Select
                label="Sales Manager"
                name="sales_manager"
                placeholder="Select Sales Manager"
                options={props.salesRepChoices.getChoices({ sortByLabel: true })}
              />
              <Select
                label="National Account Manager"
                name="national_account_manager"
                placeholder="Select National Account Manager"
                options={props.salesRepChoices.getChoices({ sortByLabel: true })}
              />
              <Select
                label="National Account"
                name="national_account"
                placeholder="Select National Account"
                options={props.nationalAccountChoices.getChoices({ sortByLabel: true })}
              />
            </Form.Row>
            <Form.Row>
              <Select
                label="Case Manager"
                name="case_manager"
                placeholder="Select Case Manager"
                options={props.caseManagerChoices.getChoices({ sortByLabel: true })}
              />
              <Select
                label="Pricing Manager"
                name="pricing_manager"
                placeholder="Select Pricing Manager"
                options={props.pricingManagerChoices.getChoices({ sortByLabel: true })}
              />
              <Select
                label="Trading Manager"
                name="auction_manager"
                placeholder="Select Trading Manager"
                options={props.auctionManagerChoices.getChoices({ sortByLabel: true })}
              />
              <Select
                label="Closing Manager"
                name="closing_manager"
                placeholder="Select Closing Manager"
                options={props.closingManagerChoices.getChoices({ sortByLabel: true })}
              />
            </Form.Row>
            <RowSeparator />
            <Form.Row>
              <Select
                label="FRID"
                name="frid"
                placeholder="Select FRID"
                options={props.fridList}
                onChange={value => {
                  handleFridChange(value);
                  if (!value) {
                    setFieldValue("frid", "");
                    setFieldValue("frid_status", []);
                    clearFridHandler();
                  }
                }}
                defaultValue={frid_state}
                submitRef={fridRef}
                md={3}
              />
              <Select
                label="FRID Status"
                name="frid_status"
                placeholder="Select FRID Status"
                options={statusChoicesDocumentStatus}
                onChange={value => {
                  handleFridStatusChange(value);
                  if (!value) {
                    setFieldValue("frid_status", "");
                    fridStatusRef?.current?.select?.state?.value && clearFridStatusHandler();
                  }
                }}
                isMulti={true}
                defaultValue={frid_status_initial}
                submitRef={fridStatusRef}
                disabled={!frid_state}
                md={3}
              />
            </Form.Row>
            <SubmitSearchButton />
            <ClearFiltersButton onClick={resetHandler} />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const agentsChoices = agentsChoicesSelector(state);
  const currentFilters = storedCasesFiltersSelector(state);
  let fridList = FRID_CHOICES;

  const parsedFilterValues = {};
  if (currentFilters?.frid) {
    parsedFilterValues.frid = fridList.find(e => e.value === currentFilters.frid);
  }
  if (currentFilters?.frid_status) {
    parsedFilterValues.frid_status = statusChoicesDocumentStatus.find(e => e.value === currentFilters.frid_status);
  }

  return {
    carrierChoices: choices.carriers,
    providerChoices: choices.case_providers,
    accountChoices: choices.accounts,
    caseManagerChoices: agentsChoices.case_managers,
    pricingManagerChoices: agentsChoices.pricing_managers,
    auctionManagerChoices: agentsChoices.auction_managers,
    closingManagerChoices: agentsChoices.closing_managers,
    salesRepChoices: choices.case_sales_representatives,
    nationalAccountChoices: choices.national_accounts,
    sourceAndBrokerChoices: choices.source_and_broker_choices,
    currentFilters: { ...parsedFilterValues, ...currentFilters },
    fridList,
  };
};

export default connect(mapStateToProps, {
  fetchLifeSettlementCases,
  fetchLifeSettlementChoices,
  fetchManagerAgents,
  storeCasesFiltersData,
  cleanCasesPaginationData,
})(CasesFilterForm);
