import React from "react";
import { connect } from "react-redux";

import { Card } from "react-bootstrap";
import { URLS } from "../../constants";
import { Tab, TabGroup } from "../../components/Tabs";
import TopHeaderTabs from "../../components/TopHeaderTabs";

const RecordsPageHeader = ({ ...props }) => {
  const tabs = [
    { link: URLS.TASKS, label: "All Tasks" },
    { link: URLS.ACCOUNT_TASKS, label: "Account Tasks" },
    { link: URLS.CASE_TASKS, label: "Case Tasks" },
    { link: URLS.CASE_REQUIREMENT_TASKS, label: "Case Requirements" },
    { link: URLS.LEAD_TASKS, label: "Lead Tasks" },
  ];

  return <TopHeaderTabs tabs={tabs} />;
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps, {})(RecordsPageHeader);
