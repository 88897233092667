import { combineReducers } from "redux";

import { SELECT_LIFE_SETTLEMENT_CASE } from "actions/types";
import choices from "./choices";
import insured from "./insured";
import owners from "./owners";
import policy from "./policy";
import lifeExpectancies from "./lifeExpectancies";
import hipaa from "./hipaa";
import medicalRecords from "./medicalRecords";
import beneficiaries from "./beneficiaries";
import insuredDiligences from "./insuredDiligences";
import policyDocuments from "./policyDocuments";
import entityDiligences from "./entityDiligences";
import personDiligences from "./personDiligences";
import signerDiligences from "./signerDiligences";
import auctions from "./auctions";
import agents from "./agents";
import emailTemplates from "./emailTemplates";
import caseFiles from "./caseFiles";
import accounts from "./accounts";
import leads from "./leads";
import predictions from "./predictions";
import records from "./records";
import activityLog from "./activityLog";
import products from "./products";
import carriers from "./carriers";
import parameters from "./parameters";
import synonyms from "./synonyms";
import caseTasks from "./caseTasks";
import clinicalResearch from "./clinicalResearch";
import mortalityAssessments from "./mortalityAssessments";
import winflex from "./winflex";

const caseId = (state = null, action) => {
  switch (action.type) {
    case SELECT_LIFE_SETTLEMENT_CASE:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  caseId,
  choices,
  insured,
  owners,
  policy,
  lifeExpectancies,
  hipaa,
  medicalRecords,
  beneficiaries,
  insuredDiligences,
  policyDocuments,
  entityDiligences,
  personDiligences,
  signerDiligences,
  auctions,
  agents,
  emailTemplates,
  caseFiles,
  accounts,
  leads,
  predictions,
  records,
  activityLog,
  products,
  carriers,
  parameters,
  synonyms,
  caseTasks,
  clinicalResearch,
  mortalityAssessments,
  winflex,
});

export const managementChoicesSelector = state => state.choices;
